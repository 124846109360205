.App {
  text-align: center;
  height: 100vh;
  background: #fafafaeb;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: color, background 2s;
}

.App::-webkit-scrollbar {
  display: none;
}

.AppNight {
  color: white;
  background: #282828;
}

