@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
*{
    font-family: 'system-ui', sans-serif;
}

body {
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
