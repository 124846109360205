@property --first {
  syntax: '<color>';
  inherits: false;
  initial-value: #e5e5e5;
}

@property --second {
  syntax: '<color>';
  inherits: false;
  initial-value: white;
}

#Projects {
    padding: 8rem 0 8rem 0;
    margin: 0;
}

.project-container{
    background: linear-gradient(to bottom, var(--first), var(--second) 2%);
    animation: lightModeBackground 2s ease forwards;
    &.dark{
        animation: darkModeBackground 2s ease forwards;
    }
}
.project-container > h2 {
    font-size: 3rem;
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    margin-top: 0;
    color: black;
    transition: color 2s;
}


.projects-grid {
    max-width: 1350px;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(575px, 1fr));
    gap: 20px 0;
    align-items: center;
}
  
.my-projects{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 2% 0;
    height: clamp(850px, 78vh, 950px);
    width: clamp(358px, 61vw, 535px);
}
  
.imgslider{
    height: clamp(200px, 50vh, 400px);
    width: clamp(358px, 61vw, 530px);
    border-radius: 15px 15px 0 0;
    border: solid;
    border-bottom: none;
    transition: color 2s;
    border-bottom: none;
}
  
.slick-list{
    border-radius: 10px 10px 0 0 !important;
}
  
.slick-slider > button {
    display: none !important;
}

.slick-slide img{
    height: clamp(200px, 50vh, 400px) !important;
    width: clamp(360px, 61vw, 530px) !important;
    border-radius: 10px 10px 0 0;
}
  
.game-description{
    flex: 2;
    border: solid;
    border-top: none;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: clamp(200px, 33vh, 500px);
    width: clamp(330px, 56.4vw, 500px);
    background: #99999933;
    padding: 15px;
    transition: color 2s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
  
.game-description::-webkit-scrollbar {
    display: none;
}
  
.game-description > h3 {
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: 0;
    margin-top: 1rem;
    flex: 1;
    color: inherit;
}
  
.game-description > p{
    flex: 5;
    font-size: clamp(1rem, 5vw, 1.3rem);
    margin-bottom: 0;
    margin-top: 5px;
    padding: 5px 3px;
    text-indent: 2%;
    line-height: 1.2em;
    text-align: justify;
    overflow: auto;
}

.project-links-container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.project-links-container > b {
    font-weight: bold;
    color: inherit;
}
  
.project-links {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
}

.summary span {
  font-size: 3rem; 
}
  
#GH{
    font-size: 3rem;
    color: #007bff;
}
  
#RFT{
    font-size: 3rem;
    color: #f2782f;
}
  
#WOV{
    font-size: 3rem;
    color: red;
}
  
.tech{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0 5px 0;
}
  
.tech > div {
    position: relative;
}
  
.tech > div > p {
    display: none;
    margin: 0;
    transition: .2s;
    height: 150%;
    width: 110%;
    align-items: center;
    justify-content: center;
}
  
.tech > div:hover > p {
    display: flex;
    position: absolute;
    bottom: 20px;
    z-index: 2;
    transition: .1s;
}

.btn {
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid transparent;
    cursor: pointer;
    border-width: 2px;
    border-radius: 4px;
    padding: 5px;
    text-decoration: none;
    background: #3a83c7e3;
    color: #fefefe;
    &:hover{
        background: #2480d6e3;
    }
}

/* ////////////////////////////////   Animations   //////////////////////////////// */
@keyframes darkModeBackground {
    from{
        --first: #e5e5e5;
        --second: white;
    }
    to{
        --first: #2a2a2a;
        --second: #202020;
    }
}

@keyframes lightModeBackground {
    from{
        --first: #2a2a2a;
        --second: #202020;
    }
    to{
        --first: #e5e5e5;
        --second: white;
    }
}
 
/* ////////////////////////////////  Tech Icons   //////////////////////////////// */
#html{
    color: #f85521;
}
  
#css{
    color: #146fb1;
}
  
#javascript{
    color: goldenrod;   
}
  
#mongodb{
    color: forestgreen;
}
  
#react {
    color: #61dafb;
}

#reactnative {
    color: #61dafb;
}

#expressjs {
    color: inherit;
}
  
#nodejs{
    color: #6aa05b;
}
  
#heroku {
    color: purple;
  }
  
#bootstrap {
    color: #700ff4;
}
  
#photoshop {
    color: #2d246e;
}

#expo {
    color: black;
}

#firebase {
    color: #eb780b;
}

#git {
    color: #ea4e32;
}

#netlify {
    color: #2ac8ba;
}
 
/* ////////////////////////////////  Project Icons   //////////////////////////////// */

.icon {
    height: 2rem !important;
    width: 3rem !important;
    transition: color .25s !important;
    &:hover{
        transition: color .25s !important;
    }
}
#gamehub-icon:hover{
    color: #007bff;
    transition: .25s;
}
  
#theroamingspoon-icon:hover{
    color: #f2782f;
    transition: .25s;
}
  
#warsofvolgrora-icon:hover{
    color: red;
    transition: .25s;
}


#psychbite-icon:hover{
    color: green;
}

.link > #external-icon{
    transition: .25s;
}

.link-dark-mode > #external-icon{
    transition: .25s;
}

.link > #external-icon:hover{
    color: #686868;
}

.link-dark-mode > #external-icon:hover{
    color: white;
}

.link > .github{
    transition: .25s;
}

.link-dark-mode > .github{
    transition: .25s;
}

.link > .github:hover{
    transition: .25s;
    color: #686868;
}

.link-dark-mode > .github:hover{
    transition: .25s;
    color: white;
}

.link {
    color: black;
    transition: color 2s;
}
.link:hover {
    color: #686868;
}

.link-dark-mode{
    color: gainsboro;
    transition: color 2s;
}

.link-dark-mode:hover{
    color: white;
}



/* ////////////////////////////////  Screen Sizes   //////////////////////////////// */
@media (max-width: 840px){
    .projects-grid{
        height: 100%;
    }

    .my-projects {
        height: 950px;
        height: fit-content;
    }

    .imgslider {
        width: 475px;
    }

    .game-description {
        width: 445px;
        height: 850px;
        height: fit-content;
    }

    .game-description > p {
        font-size: 1.5rem;
        overflow: unset;
    }

    .slick-slide img {
        width: 100% !important;
    }
}


@media (max-width: 550px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }

    .my-projects {
        width: 480px;
    }
}


@media (max-width: 450px){
    #Projects {
        padding: 6rem 0 6rem 0;
    }

    .projects-grid {
        width: 100%;
        margin: 0 auto;
    }

    .my-projects{
        margin: 0 auto;
        width: 350px;
        height: 890px;
        height: fit-content;
    }

    .imgslider {
        height: 250px;
        width: 340px;
    }

    .game-description {
        width: 330px;
        padding: 5px 5px 20px;
        height: 500px;
    }

    .game-description > p {
        padding: 8px 3px;
        font-size: 1.1rem;
        overflow: unset;
    }

    .summary span {
        font-size: 2.5rem;
    }

    .slick-slide img {
        height: 250px !important;
        width: 340px !important;
    }

    .icon {
        height: 1.5rem !important;
        width: 1.5rem !important;
    }
}


@media (max-width: 345px){
  .my-projects{
        margin: 0 auto;
        width: 250px;
        height: 830px;
        height: fit-content;
    }

    .imgslider {
        height: 250px;
        width: 250px;
    }

    .game-description {
        width: 240px;
        height: 550px;
    }

    .tech {
        padding: 10px 0;
        flex-wrap: wrap;
        gap: 5px;
    }

    .btn {
        font-size: 0.8rem;
    }
}

