#case-study-light-mode {
  background: #fafafaeb;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color, background 2s;
}

#case-study-dark-mode {
  background: #282828;
  color: gainsboro;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color, background 2s;
}

.case-study-name {
  color: inherit;
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  transition: color 2s;
  text-transform: uppercase;
}

.case-study-image {
  width: 80%;
  height: clamp(200px, 65vw, 800px);
  max-height: 800px;
  max-width: 1000px;
  margin: 2rem 0 1rem;
  border: solid;
  border-radius: 10px;
}

.case-study-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 6rem 0;
  max-width: 90rem;
  margin: auto;
  color: inherit;
}

.case-study-title {
  font-weight: 700;
  font-size: clamp(1.7rem, 5vw, 2.8rem);
  margin-bottom: clamp(1.5rem, 5vw, 3rem);
  color: inherit;
  transition: color 2s;
}

.case-study-content > p {
    text-align: center;
    font-size: clamp(1rem, 5vw, 1.5rem);
    font-weight: 400;
    max-width: 60rem;
    line-height: 1.5;
    margin: 0 auto;
    text-indent: 2%;
    color: inherit;
    transition: color 2s;
}

.case-study-tools {
  padding-bottom: 6rem;
}

.case-study-skills {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 1rem;
}

.case-study-skills  > li {
    padding: .8rem 1.5rem;
    font-size: 1rem;
    background: rgba(153, 153, 153, 0.2);
    border-radius: 5px;
    font-weight: bold;
    color: inherit;
    transition: color 2s;
}

.case-study-btn {
    font-size: clamp(1rem, 5vw, 1.5rem);
    font-weight: 600;
    border: 1px solid transparent;
    cursor: pointer;
    border-width: 2px;
    border-radius: 4px;
    padding: .5rem 1rem;
    text-decoration: none;
    background: #3a83c7e3;
    color: #fefefe;
    &:hover{
        background: #2480d6e3;
    }
}

.case-study-links {
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;
  align-items: center;

}

.case-study-links > div {
  display: flex;
  gap: 1rem;
}


/* ////////////////////////////////  Screen Sizes   //////////////////////////////// */
@media (max-width: 450px){
  .case-study-content {
    padding: 4rem 0;
  }

  .case-study-tools {
    padding-bottom: 4rem;
  }

  .case-study-links {
    padding-bottom: 5rem;
  }

}

@media (max-width: 400px){
}

